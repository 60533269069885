
import '@/assets/styles/imports/modal-download.sass'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalSuccessSubscription extends Vue {
  eventClose() {
    window.location.reload();
    //this.$emit("close")
  }
}
