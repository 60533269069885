
import '@/assets/styles/imports/modal-error.sass'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalNote extends Vue {
  eventOk () {
    this.$emit('ok')
  }

  eventClose () {
    this.$emit('close')
  }
}
