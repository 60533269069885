
import '@/assets/styles/imports/modal-process.sass'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TaskInterface } from '@/interfaces/task'
import Patch = TaskInterface.Patch
import storage from '@/storage'
import getLangId from '@/additionally/getLangId'
import api from '@/api'

@Component
export default class ModalProcess extends Vue {
  @Prop({ type: String, default: 'title' }) readonly title!: string
  langId = 1

  getLanguage () {
    this.langId = getLangId()
  }


  // eventClose () {
  //   this.$router.push({name: "History"})
  //   //this.$emit('close');
  // }

  created () {
    this.getLanguage()
    storage.lastPaymentId = null
  }
}
