
import "@/assets/styles/imports/modal-stock.sass";
import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api";
import getLangIdObject from "@/additionally/getLangIdObject";
import {PaymentProvider} from "@/api/payment";

@Component
export default class ModalSubscription extends Vue {
  @Prop({ required: true }) readonly subscriptionId!: any;
  @Prop({ required: true }) readonly cost!: any;
  @Prop({ required: true }) readonly currency!: any;
  @Prop({}) readonly desc!: any;


  intervalId: any = null;
  paymentScriptParams: any = null;
  provider: PaymentProvider = 'Robocassa'
  isProdamusSuccess = false
  isProdamusError = false

  async created() {
    this.provider = await api.payment.getDefaultProvider() || 'Robocassa'
    this.paymentScriptParams = await api.subscription.getPaymentScriptParams(
      this.subscriptionId,
      this.provider
    );
    if (!this.paymentScriptParams) this.$emit('order-error')
  }

  getDesc(): void {
    return getLangIdObject(this.desc).descr;
  }

  destroyed() {
    clearInterval(this.intervalId);
    this.removeProdamusListeners()
  }

  closeModal() {
    this.$emit("close");
  }

  payment() {
    if (this.provider === 'Prodamus') {
      this.$emit('start-prodamus-payment')
      this.addProdamusListeners()
      // @ts-expect-error
      // eslint-disable-next-line
      payformInit('ecudtc.payform.ru', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        order_sum: this.paymentScriptParams.sum,
        currency: this.paymentScriptParams.currency,
        // eslint-disable-next-line @typescript-eslint/camelcase
        order_id: this.paymentScriptParams.order_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        order_num: this.paymentScriptParams.order_num,
        // eslint-disable-next-line @typescript-eslint/camelcase
        customer_phone: this.paymentScriptParams.customer_phone,
        // eslint-disable-next-line @typescript-eslint/camelcase
        customer_email: this.paymentScriptParams.customer_email
      })
    } else {
      // @ts-expect-error
      // eslint-disable-next-line
      Robokassa.StartPayment(this.paymentScriptParams);
    }

    this.intervalId = setInterval(() => {
      api.subscription
        .checkPayStatus(this.subscriptionId)
        .then((response: any) => {
          if (response === "Paid") {
            clearInterval(this.intervalId);
            // @ts-expect-error
            // eslint-disable-next-line
            Robokassa.ClosePaymentForm();
            this.closeModal();
            this.$emit("success-paid");
          }
        });
    }, 1000);

    //this.closeModal();
  }

  eventProdamus (event: any) {
    if (event.data.status === "close") {
      if (this.isProdamusSuccess) return
      this.$emit('cancel-prodamus-payment')
      if (this.isProdamusError) {
        this.$emit('prodamus-error')
      }
    }
    if (event.data.status === "success") {
      this.isProdamusSuccess = true
    }
    if (event.data.status === "error") {
      this.isProdamusError = true
    }
  }

  addProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  removeProdamusListeners () {
    window.addEventListener("message", this.eventProdamus)
  }

  mounted() {
    //this.payment();
  }
}
